import { useEffect } from "react";
import Logo from '../assets/logo-mv.webp';
import './pages.css';

const Loader = () => {

    let html = document.getElementsByTagName("html");

    useEffect(() => {
        setTimeout(() => {
            html[0].style.overflow = "inherit"
        }, 2000);
    }, [])

    return (
        <div className="loader-container">
            <img id="loader-img" src={Logo} alt="logo that represents the initials m and v to refer to matthias vimbert"/>
        </div>
    )
}

export default Loader