import { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import ReCAPTCHA from "react-google-recaptcha";
import { Text } from '../containers/Language';
import Navbar from "../sections/navbar/Navbar";
import Footer from "../sections/footer/Footer";
import Check from '../assets/check.png';

const Contact = ({ mouseOutEvent, mouseOverEvent, mouseX, mouseY, isScroll }) => {

    const form = useRef();
    const [isHuman, setIsHuman] = useState(null);
    const [isSend, setIsSend] = useState(false);
    const [inputName, setInputName] = useState('');
    const [inputMail, setInputMail] = useState('');
    const [inputMessage, setInputMessage] = useState('');

    const sendEmail = (e) => {
        e.preventDefault();
        console.log(e)
        console.log(form.current)

        if(isHuman === true){
            emailjs.sendForm('service_df8v8sm', 'template_fd97p2f', form.current, 'wvHrG9zVAq0gM5NXj')
                .then((result) => {
                    console.log(result.text);
                }, (error) => {
                    console.log(error.text);
                });
            setIsSend(true)
            setInputName("");
            setInputMail("");
            setInputMessage("");
        }
    };


    return (
        <div>
            <Navbar
                mouseOverEvent={mouseOverEvent}
                mouseOutEvent={mouseOutEvent}
                isScroll={isScroll}
                mouseY={mouseY}
            />
            <div className="contact-block" data-aos="fade-in">
                <h1><Text tid="contact-me" /></h1>
                <div className="container">
                    <form ref={form} onSubmit={sendEmail}>
                        <label htmlFor="fname"><Text tid="name" /></label>
                        <input type="text" id="name" name="user_name" value={inputName} onChange={(e) => setInputName(e.target.value)} onMouseOver={mouseOverEvent} onMouseOut={mouseOutEvent}/>
                        <label htmlFor="lname"><Text tid="mail" /></label>
                        <input type="email" id="lname" name="user_email" value={inputMail} onChange={(e) => setInputMail(e.target.value)} onMouseOver={mouseOverEvent} onMouseOut={mouseOutEvent}/>
                        <label htmlFor="subject"><Text tid="message" /></label>
                        <textarea id="subject" name="message" placeholder="Write something.." value={inputMessage} onChange={(e) => setInputMessage(e.target.value)} onMouseOver={mouseOverEvent} onMouseOut={mouseOutEvent}/>
                        {/* CAPTCHA GOOGLE BOT */}
                        <ReCAPTCHA
                            sitekey="6LdBmMYfAAAAAKCfFKCRbm6u_2yoPwREYJKIdjck"
                            onChange={() => setIsHuman(true)}
                        />,
                        <input type="submit" value="SUBMIT" onMouseOver={mouseOverEvent} onMouseOut={mouseOutEvent}/>
                    </form>
                </div>
                <div className={`contact-modal ${isSend ? 'contact-modal-active' : ''}`}>
                    <div className="cookiesContent" id="cookiesPopup">
                        <button className="close" onClick={() => setIsSend(false)}>✖</button>
                        <img src={Check} alt="check-img"/>
                        <p><Text tid="send-form" /></p>
                        <button className="accept" onClick={() => setIsSend(false)}>OK!</button>
                    </div>
                </div>
            </div>
            <Footer
                mouseOverEvent={mouseOverEvent}
                mouseOutEvent={mouseOutEvent}
            />
        </div>
    )
}

export default Contact