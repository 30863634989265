import { Element } from 'react-scroll';
import { Text } from '../../containers/Language';
import BtnContact from "../../components/btn-contact/BtnContact";
import './bannerEstimate.css';

const BannerEstimate = ({ mouseOutEvent, mouseOverEvent }) => {
    return (
        <Element name="contact" className="element">
            <div className="banner-estimate-container">
                <h2><Text tid="got-project" /> </h2>
                <hr/>
                <p><Text tid="contact-me-merch" /></p>
                <BtnContact mobile={true} text={<Text tid="need-quote" />} onMouseOver={mouseOverEvent} onMouseOut={mouseOutEvent}  />
            </div>
        </Element>
    )
}

export default BannerEstimate