import { AiOutlineLine } from 'react-icons/ai';
import './titleSection.css';

const TitleSection = (props) => {
    return (
        <div className="title-section-block">
            <AiOutlineLine />
            <h3>{props.title}</h3>
        </div>
    )
}

export default TitleSection