import { useState } from 'react';
import { Element } from 'react-scroll';
import { BsArrowRightCircle } from 'react-icons/bs';
import { Text } from '../../containers/Language';
import Siny from '../../assets/siny.webp';
import Orane from '../../assets/orane.webp'
import OraneMobile from '../../assets/orane-mobile.webp'
import SinyMobile from '../../assets/siny-mobile.webp';
import Bondi from '../../assets/bondi.webp';
import BondiMobile from '../../assets/bondi-mobile.webp';
import './featuredProjects.css';

const FeaturedProjects = ({ mouseOutEvent, mouseOverEvent }) => {

    let deviceType = window.innerWidth;

    const [firstProjectHover, setFirstProjectHover] = useState(false);
    const [secondProjectHover, setSecondProjectHover] = useState(false);
    const [thirdProjectHover, setThirdProjectHover] = useState(false);

    const onMouseHoverProject = (e) => {
        mouseOverEvent()
        if(e.target.offsetParent.id === "featured-project-first"){
            setFirstProjectHover(true)
        } else if(e.target.offsetParent.id === "featured-project-second"){
            setSecondProjectHover(true)
        } else if(e.target.offsetParent.id === "featured-project-third"){
            setThirdProjectHover(true)
        }
    }

    const onMouseLeaveProject = (e) => {
        mouseOutEvent()
        if(e.target.offsetParent.id === "featured-project-first"){
            setFirstProjectHover(false)
        } else if(e.target.offsetParent.id === "featured-project-second"){
            setSecondProjectHover(false)
        } else if(e.target.offsetParent.id === "featured-project-third"){
            setThirdProjectHover(false)
        }
    }


    return (
        <Element name="projects" className="element">
            <div className="featured-projects-container">
                <h2><Text tid="featured-project" /></h2>
                <div className="featured-projects-holder">
                <div data-aos="zoom-out" data-aos-delay="600">
                        <a href="https://front-app-film.vercel.app/" target="_blank" rel="noreferrer">
                            <div
                                id="featured-project-second"
                                className={`featured-project ${secondProjectHover ? "hover-featured-project" : ""}`}
                                onMouseEnter={(e) => onMouseHoverProject(e)}
                                onMouseLeave={(e) => onMouseLeaveProject(e)}
                            >
                                <div className="black-filter"/>
                                <img src={deviceType <= 800 ? OraneMobile : Orane} alt=""/>
                                <div className={`featured-project-resume ${deviceType <= 800 ? "hover-project" : secondProjectHover ? "hover-project" : ""}`}>
                                    <h3>LES FILMS DE LA BANDE</h3>
                                    <h4>FULLSTACK APP</h4>
                                    <span>REACTJS TYPESCRIPT</span>
                                    <span>EXPRESSJS</span>
                                    <span>MYSQL</span>
                                    <span>JEST</span>
                                    <span>AWS VERCEL HEROKU</span>
                                    <span>GOOGLE ANALYTICS</span>
                                    <p>Création d'une application fullstack pour une entreprise dans l'audiovisuel</p>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div data-aos="zoom-out" data-aos-delay="100">
                        <a href="https://bondi-restaurant.com/" target="_blank" rel="noreferrer">
                            <div
                                id="featured-project-first"
                                className={`featured-project ${firstProjectHover ? "hover-featured-project" : ""}`}
                                onMouseEnter={(e) => onMouseHoverProject(e)}
                                onMouseLeave={(e) => onMouseLeaveProject(e)}
                            >
                                <div className="black-filter"/>
                                <img src={deviceType <= 800 ? BondiMobile : Bondi} alt=""/>
                                <div className={`featured-project-resume ${deviceType <= 800 ? "hover-project" : firstProjectHover ? "hover-project" : ""}`}>
                                    <h3>BONDI RESTAURANT</h3>
                                    <h4>LANDING PAGE</h4>
                                    <span>HTML CSS JS</span>
                                    <span>FIREBASE</span>
                                    <span>GOOGLE ANALYTICS</span>
                                    <p>Création d'un site vitrine pour un restaurant bordelais</p>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div data-aos="zoom-out" data-aos-delay="1200">
                        <a href="https://www.sinyagency.com/" target="_blank" rel="noreferrer">
                            <div
                                id="featured-project-third"
                                className={`featured-project ${thirdProjectHover ? "hover-featured-project" : ""}`}
                                onMouseEnter={(e) => onMouseHoverProject(e)}
                                onMouseLeave={(e) => onMouseLeaveProject(e)}
                            >
                                <div className="black-filter"/>
                                <img src={deviceType <= 800 ? SinyMobile : Siny} alt=""/>
                                <div className={`featured-project-resume ${deviceType <= 800 ? "hover-project" : thirdProjectHover ? "hover-project" : ""}`}>
                                    <h3>SINY AGENCY</h3>
                                    <h4>LANDING PAGE</h4>
                                    <span>NEXTJS</span>
                                    <span>TYPESCRIPT</span>
                                    <span>VERCEL</span>
                                    <span>GOOGLE ANALYTICS</span>
                                    <p>Création d'un site vitrine pour une agence web bordelaise</p>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
                <a href="https://github.com/MatthiasTeks" target="_blank" rel="noreferrer">
                    <div className="featured-project-holder-more" onMouseOver={mouseOverEvent} onMouseOut={mouseOutEvent}>
                        <svg width="0" height="0">
                            <linearGradient id="blue-gradient">
                                <stop stopColor="rgba(41,152,255,1)" offset="0%" />
                                <stop stopColor="rgba(0,255,136,1)" offset="100%" />
                            </linearGradient>
                        </svg>
                        <BsArrowRightCircle style={{ fill: "url(#blue-gradient)" }}/>
                        <p className="see-more-project"><Text tid="see-all-project" /></p>
                    </div>
                </a>
            </div>
        </Element>
    )
}

export default FeaturedProjects