import { useState } from 'react';
import Arrow from '../../assets/curve-arrow.webp';
import { Text } from '../../containers/Language';
import { AiOutlineClose } from 'react-icons/ai';
import TitleSection from "../../components/title-section/TitleSection";
import Figma from '../../assets/figma.webp';
import Backend from '../../assets/backend.webp';
import Development from '../../assets/development.webp';
import Digital from '../../assets/digital.webp';
import EllipseLeft from '../../assets/ellipse-left.webp';
import EllipseRight from '../../assets/ellipse-right.webp';
import Html from '../../assets/html-5.webp';
import Css from '../../assets/css-3.webp';
import Javascript from '../../assets/js.webp';
import ReactLogo from '../../assets/react.webp';
import Node from '../../assets/nodejs.webp';
import Sql from '../../assets/mysql.webp';
import Php from '../../assets/php.webp';
import FigmaLogo from '../../assets/figma-logo.webp';
import Xd from '../../assets/xd.webp';
import Firebase from '../../assets/firebase.webp';
import Aws from '../../assets/aws.webp';
import Ovh from '../../assets/ovh.webp';
import Heroku from '../../assets/heroku.webp';
import './actions.css';

const Actions = ({ mouseOutEvent, mouseOverEvent }) => {

    const [userKnowMore, setUserKnowMore] = useState(false);
    const [detail, setDetail] = useState("");
    const [secondDetail, setSecondDetail] = useState("");

    const handleKnowMore = (action) => {
        setUserKnowMore(true)
        if(action === "first"){
            setDetail("first-action-more")
            setSecondDetail("first-action-more-second")
        } else if(action === "second"){
            setDetail("second-action-more")
        } else if(action === "third"){
            setDetail("third-action-more")
        } else if (action === "fourth"){
            setDetail("fourth-action-more")
            setSecondDetail("fourth-action-more-second")
        }
    }

    return (
        <div className="actions-container">
            <h1><Text tid="web-conception" /></h1>
            {/* ACTIONS 1 */}
            <div className="action-row" style={{paddingTop: 2+'vh'}}>
                <div className="action-resume" data-aos="fade-right">
                    <TitleSection title="SKILL FRONTEND"/>
                    <h2><Text tid="first-action"/></h2><br/>
                    <p><Text tid="first-action-more"/></p><br/>
                    <p><Text tid="first-action-more-second" /></p>
                    <div className='action-logo'>
                        <img src={Html} alt='company logo of HTML'/>
                        <img src={Css} alt='company logo of CSS'/>
                        <img src={Javascript} alt='company logo of Javascript'/>
                        <img src={ReactLogo} alt='company logo of ReactJS'/>
                    </div>
                </div>
                <div className="action-visual">
                    <div className="action-visual-rotate">
                        <img src={Development} alt="test" onClick={() => handleKnowMore("first")}/>
                    </div>
                </div>
                <div className="action-mobile-resume" onClick={() => handleKnowMore("first")}>
                    <img src={Arrow} alt="arrow know more"/>
                    <p><Text tid='know-more' /></p>
                </div>
                <img className="ellipse ellipse-right" src={EllipseRight} alt="Gradient ellipse"/>
            </div>
            {/* ACTIONS 2 */}
            <div className="action-row">
                <div className="action-visual desktop-visual">
                    <div className="action-visual-rotate">
                        <img src={Backend} alt="test"/>
                    </div>
                </div>
                <div className="action-resume" data-aos="fade-left" style={{paddingLeft: 5+'vw'}}>
                    <TitleSection title="SKILL BACKEND"/>
                    <h2><Text tid="second-action"/></h2><br/>
                    <p><Text tid="second-action-more"/></p>
                    <div className='action-logo'>
                        <img src={Node} alt='company logo of NodeJS'/>
                        <img src={Php} alt='company logo of Php'/>
                        <img src={Sql} alt='company logo of SQL'/>
                    </div>
                </div>
                <div className="action-visual mobile-visual">
                    <div className="action-visual-rotate">
                        <img src={Backend} alt="test" onClick={() => handleKnowMore("second")}/>
                    </div>
                </div>
                <div className="action-mobile-resume" onClick={() => handleKnowMore("second")}>
                    <img src={Arrow} alt="arrow know more"/>
                    <p><Text tid='know-more' /></p>
                </div>
                <img className="ellipse ellipse-left" src={EllipseLeft} alt="Gradient ellipse"/>
            </div>
            {/* ACTIONS 3 */}
            <div className="action-row">
                <div className="action-resume" data-aos="fade-right">
                    <TitleSection title="SKILL DESIGN"/>
                    <h2><Text tid="third-action"/></h2><br/>
                    <p><Text tid="third-action-more"/></p>
                    <div className='action-logo'>
                        <img src={FigmaLogo} alt='company logo of Figma'/>
                        <img src={Xd} alt='company logo of Adobe XD'/>
                    </div>
                </div>
                <div className="action-visual">
                    <div className="action-visual-rotate">
                        <img src={Figma} alt="Digital network asset branding" onClick={() => handleKnowMore("third")}/>
                    </div>
                </div>
                <div className="action-mobile-resume" onClick={() => handleKnowMore("third")}>
                    <img src={Arrow} alt="arrow know more"/>
                    <p><Text tid='know-more' /></p>
                </div>
                <img className="ellipse ellipse-right" src={EllipseRight} alt="Gradient ellipse"/>
            </div>
            <h1><Text tid="digitization" /></h1>
            {/* ACTIONS 4 */}
            <div className="action-row" style={{paddingTop: 10+'vh'}}>
                <div className="action-visual desktop-visual">
                    <div className="action-visual-rotate">
                        <img src={Digital} alt="Social medias asset branding"/>
                    </div>
                </div>
                <div className="action-resume" data-aos="fade-left" style={{paddingLeft: 5+'vw'}}>
                    <TitleSection title={<Text tid="process-digital" />}/>
                    <h2><Text tid="fourth-action"/></h2><br/>
                    <p><Text tid="fourth-action-more"/></p><br/>
                    <div className='action-logo'>
                        <img src={Firebase} alt='company logo of Firebase'/>
                        <img src={Heroku} alt='company logo of Heroku'/>
                        <img src={Aws} alt='company logo of AWS'/>
                        <img src={Ovh} alt='company logo of OVH'/>
                    </div>
                </div>
                <div className="action-visual mobile-visual">
                    <div className="action-visual-rotate">
                        <img src={Digital} alt="Figma asset branding" onClick={() => handleKnowMore("fourth")}/>
                    </div>
                </div>
                <div className="action-mobile-resume" onClick={() => handleKnowMore("fourth")}>
                    <img src={Arrow} alt="arrow know more"/>
                    <p><Text tid='know-more' /></p>
                </div>
            </div>
            {/* KNOW MORE */}
            <div className={`display-know-more ${userKnowMore ? 'know-more-active' : ''}`}>
                <div className="know-more-content">
                    <AiOutlineClose onClick={() => setUserKnowMore(!userKnowMore)}/>
                    <p><Text tid={detail} /></p><br/>
                    <p><Text tid={secondDetail} /></p>
                </div>
            </div>
        </div>
    )
}

export default Actions