import { LanguageProvider } from './containers/Language';
import Control from './utils/Control';

function App() {
    return (
      <LanguageProvider>
          <Control />
      </LanguageProvider>
    );
}

export default App;
