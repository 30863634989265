import { Element } from 'react-scroll';
import { Text } from '../../containers/Language';
import TitleSection from "../../components/title-section/TitleSection";
import './services.css';

const Services = () => {

    return (
        <Element name="services" className="element">
            <div className="services-container">
                <div className="services-resume-block">
                    <TitleSection title={<Text tid="my-services" />}/>
                    <h2><Text tid="hiring" /></h2>
                    <p><Text tid="my-value" /></p>
                </div>
            </div>
        </Element>
    )
}

export default Services