import { useRef } from 'react';
import { Element } from 'react-scroll';
import { BsArrowDownShort } from 'react-icons/bs';
import { Text } from '../../containers/Language';
import TitleSection from "../../components/title-section/TitleSection";
import { Link } from 'react-scroll';
import Portrait from '../../assets/portrait.webp';
import './home.css';

const Home = ({ mouseOverEvent, mouseOutEvent, mouseY, mouseX }) => {

    const transparentBackground = useRef(null);
    const cursorBackground = useRef(false);

    const toggleCursorBackground = () => { /* Function that display gradient on transparent background */
        if(cursorBackground.current){
            transparentBackground.current.style.backgroundPosition = `${Math.floor(mouseX.current / 2 - 100)}px ${Math.floor(mouseY.current / 2 - 200)}px`;
        } else {
            transparentBackground.current.style.backgroundPosition = "0 -300px";
        }
    }

    const MouseMoveHomeEvent = () => {
        cursorBackground.current = true;
        toggleCursorBackground();
    }

    const MouseOutHomeEvent = () => {
        cursorBackground.current = false;
        toggleCursorBackground();
    }

    return (
        <Element name="home" className="element">
            <div className="home-container">
                <div className="cursor-zone" onMouseMove={MouseMoveHomeEvent} onMouseOut={MouseOutHomeEvent}/>
                <div className="home-block-picture-mobile">
                    <div className="block-filter-picture"/>
                    <div className="holder-picture-mobile">
                        <img id="home-picture" src={Portrait} alt="frontal portrait of Matthias Vimbert"/>
                    </div>
                </div>
                <div className="home-block-introducing">
                    <TitleSection title={<Text tid={"presentation"} />}/>
                    <p className="home-merch"><Text tid="hello" /></p>
                    <p
                        ref={transparentBackground}
                        className="home-merch transparent"
                        style={{backgroundPosition: '100px 100px'}}
                        data-aos="fade-in"
                        onMouseOut={mouseOutEvent}
                        onMouseOver={mouseOverEvent}
                    >
                        <Text tid="introduce" />
                    </p>
                    <p className="home-resume"><Text tid="resume" /></p>
                </div>
                <div className="home-block-picture">
                    <img id="home-picture" src={Portrait} alt="frontal portrait of Matthias Vimbert"/>
                </div>
                <Link to="services" spy={true} smooth={true} >
                    <div className="home-scroll" onMouseOut={mouseOutEvent} onMouseOver={mouseOverEvent}>
                        <p>scroll</p>
                        <BsArrowDownShort />
                    </div>
                </Link>
            </div>
        </Element>
    )
}

export default Home