import CountUp from 'react-countup';
import { useInView } from 'react-intersection-observer';
import { Text } from '../../containers/Language';
import './bannerMerch.css';

const BannerMerch = () => {

    const { ref, inView } = useInView();

     return (
         <div className="banner-merch-container" ref={ref}>
             <div className="banner-merch-stat">
                 { inView &&
                     <CountUp end={8} ref={ref}/>
                 }
                 <p><Text tid="happy-client" /></p>
             </div>
             <hr/>
             <div className="banner-merch-stat">
                 { inView &&
                     <CountUp end={8}/>
                 }
                 <p><Text tid="project-done" /></p>
             </div>
             <hr/>
             <div className="banner-merch-stat">
                 { inView &&
                     <CountUp end={3}/>
                 }
                 <p><Text tid="years-experience" /></p>
             </div>
         </div>
     )
}

export default BannerMerch