import { PopupWidget } from "react-calendly";
import './btnContact.css';

const BtnContact = ({ mouseOverEvent, mouseOutEvent, type }) => {
    return (
        <div id={type === "first" ? "first" : ""} onMouseOver={mouseOverEvent} onMouseOut={mouseOutEvent}>
            <PopupWidget
                url="https://calendly.com/matthias-vimbert"
                rootElement={document.getElementById("root")}
                text="CONTACT"
                onMouseOut={mouseOutEvent}
                onMouseOver={mouseOverEvent}
                color="transparent"
            />
        </div>
    )
}

export default BtnContact