import { useContext, useState, useRef, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Link } from 'react-scroll';
import { GiEarthAfricaEurope } from 'react-icons/gi';
import { Text } from '../../containers/Language';
import { LanguageContext } from '../../containers/Language';
import BtnContact from "../../components/btn-contact/BtnContact";
import MyLogo from '../../assets/logo-mv.webp';
import "./navbar.css";

const Navbar = ({ mouseOutEvent, mouseOverEvent, isScroll, burgerOpen, setBurgerOpen}) => {

    const { userLanguageChange } = useContext(LanguageContext);
    const [langOpen, setLangOpen] = useState(false);

    const inputCheck = useRef();
    let navigate = useNavigate();
    let location = useLocation();

    const changeLang = (lang) => { // change website language
        if(lang){
            userLanguageChange(lang)
        }
    }

    const handleClickLink = (lang) => {
        navigate("/", { replace: true })
        setBurgerOpen(false);
        inputCheck.current.checked = false;
        if(lang){
            userLanguageChange(lang)
        }
    }

    useEffect(() => { // check scrolling, if user are on top page, disable burger menu and reset input check btn burger
        if (isScroll === 0) {
            setBurgerOpen(false);
            inputCheck.current.checked = false;
        }
    }, [isScroll])

    return (
        <nav>
            {/* NAVBAR DESKTOP */}
            <div className="navbar-block" id="navbar-first-block">
                <Link to="home" spy={true} smooth={true} onClick={() => handleClickLink()}>
                    <img
                        src={MyLogo}
                        alt="logo that represents the initials m and v to refer to matthias vimbert"
                        onMouseOut={mouseOutEvent}
                        onMouseOver={mouseOverEvent}
                        onClick={() => handleClickLink()}
                    />
                </Link>
                <ul className={`display-nav ${isScroll >= 100 ? "hide-nav" : ""}`}>
                    { location.pathname !== "/contact" &&
                        <>
                            <Link to="home" spy={true} smooth={true} onClick={() => handleClickLink()}>
                                <li onMouseOut={mouseOutEvent} onMouseOver={mouseOverEvent}>
                                    <Text tid="home" />
                                </li>
                            </Link>
                            <Link to="services" spy={true} smooth={true} onClick={() => handleClickLink()}>
                                <li onMouseOut={mouseOutEvent} onMouseOver={mouseOverEvent}>
                                    <Text tid="services" />
                                </li>
                            </Link>
                            <Link to="projects" spy={true} smooth={true} onClick={() => handleClickLink()}>
                                <li onMouseOut={mouseOutEvent} onMouseOver={mouseOverEvent}>
                                    <Text tid="projects" />
                                </li>
                            </Link>
                            <Link to="contact" spy={true} smooth={true} onClick={() => handleClickLink()}>
                                <li onMouseOut={mouseOutEvent} onMouseOver={mouseOverEvent}>
                                    <Text tid="contact" />
                                </li>
                            </Link>
                        </>
                    }
                </ul>
            </div>
            {/* LANGUAGE & CONTACT */}
            <div className="navbar-block" id="navbar-second-block">
                <div className="popover-wrapper">
                    <div
                        className="btn-mv popover-btn-lang"
                        onClick={() => setLangOpen(!langOpen)}
                        onMouseOut={mouseOutEvent}
                        onMouseOver={mouseOverEvent}
                        style={{
                            background: `${langOpen ? "var(--second-color)" : "var(--fourth-color)"}`,
                            display: `${isScroll >= 100 ? "none" : "flex"}`
                        }}
                    >
                        <GiEarthAfricaEurope style={{color: `${langOpen ? "var(--third-color)" : "var(--text-primary-color)"}`}}/>
                    </div>
                    <div className={`popover-content ${langOpen ? isScroll <= 100 ? "popover-content-active" : "" : ""}`}>
                        <p onMouseOut={mouseOutEvent} onMouseOver={mouseOverEvent} onClick={() => changeLang('fr')}><Text tid="french" /></p>
                        <p onMouseOut={mouseOutEvent} onMouseOver={mouseOverEvent} onClick={() => changeLang('en')}><Text tid="english" /></p>
                    </div>
                </div>
                <BtnContact
                    text="contact-btn"
                    mouseOutEvent={mouseOutEvent}
                    mouseOverEvent={mouseOverEvent}
                    display={isScroll >= 100 ? "hide" : "display"}
                    type="first"
                />
                {/*  BUTTON BURGER  */}
                <div className={`btn-burger ${isScroll >= 100 ? "display-burger" : ""}`} >
                    <label htmlFor="check" onMouseOut={mouseOutEvent} onMouseOver={mouseOverEvent}>
                        <input ref={inputCheck} type="checkbox" id="check" onClick={() => setBurgerOpen(!burgerOpen)} />
                        <span/>
                        <span/>
                        <span/>
                    </label>
                </div>
            </div>
            {/*  MENU BURGER  */}
            <div className={`show-burger ${burgerOpen ? "show-burger-active" : ""}`}>
            { burgerOpen === true &&
                <div className="nav-burger">
                    <ul className="display-nav-burger">
                        <Link to="home" spy={true} smooth={true} onClick={() => handleClickLink()}>
                            <li onMouseOut={mouseOutEvent} onMouseOver={mouseOverEvent}>
                                <Text tid="home" />
                            </li>
                        </Link>
                        <Link to="services" spy={true} smooth={true} onClick={() => handleClickLink()}>
                            <li onMouseOut={mouseOutEvent} onMouseOver={mouseOverEvent}>
                                <Text tid="services" />
                            </li>
                        </Link>
                        <Link to="projects" spy={true} smooth={true} onClick={() => handleClickLink()}>
                            <li onMouseOut={mouseOutEvent} onMouseOver={mouseOverEvent}>
                                <Text tid="projects" />
                            </li>
                        </Link>
                        <Link to="contact" spy={true} smooth={true} onClick={() => handleClickLink()}>
                            <li onMouseOut={mouseOutEvent} onMouseOver={mouseOverEvent}>
                                <Text tid="contact" />
                            </li>
                        </Link>
                        <div className="lang-mobile">
                            <p onMouseOut={mouseOutEvent} onMouseOver={mouseOverEvent} onClick={() => handleClickLink('fr')}>
                                <Text tid="french-long" />
                            </p>
                            <p onMouseOut={mouseOutEvent} onMouseOver={mouseOverEvent} onClick={() => handleClickLink('en')}>
                                <Text tid="english-long" />
                            </p>
                        </div>
                    </ul>
                </div>
            }
            </div>
        </nav>
    )
}

export default Navbar