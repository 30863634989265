import Aos from 'aos';
import { useEffect, useState } from "react";
import Home from "../sections/home/Home";
import Navbar from "../sections/navbar/Navbar";
import Services from "../sections/services/Services";
import Actions from "../sections/actions/Actions";
import BannerMerch from "../sections/banner-merch/BannerMerch";
import FeaturedProjects from "../sections/featured-projects/FeaturedProjects";
import BannerEstimate from "../sections/banner-estimate/BannerEstimate";
import Footer from "../sections/footer/Footer";
import BtnContactRounded from "../components/btn-contact-rounded/BtnContactRounded";
import "aos/dist/aos.css";

const Main = ({ mouseOutEvent, mouseOverEvent, mouseX, mouseY, isScroll }) => {

    const [burgerOpen, setBurgerOpen] = useState(false);

    useEffect(() => { /* Init package for animation on scroll */
           Aos.init({
               duration: 2000,
           });
    }, []);

    return (
        <div>
            <Navbar
                mouseOverEvent={mouseOverEvent}
                mouseOutEvent={mouseOutEvent}
                burgerOpen={burgerOpen}
                setBurgerOpen={setBurgerOpen}
                isScroll={isScroll}
                mouseY={mouseY}
            />
            <Home
                mouseOverEvent={mouseOverEvent}
                mouseOutEvent={mouseOutEvent}
                mouseX={mouseX}
                mouseY={mouseY}
            />
            <Services
                mouseOverEvent={mouseOverEvent}
                mouseOutEvent={mouseOutEvent}
            />
            <BtnContactRounded />
            <Actions
                mouseOverEvent={mouseOverEvent}
                mouseOutEvent={mouseOutEvent}
            />
            <BannerMerch />
            <FeaturedProjects
                mouseOverEvent={mouseOverEvent}
                mouseOutEvent={mouseOutEvent}
            />
            <BannerEstimate
                mouseOverEvent={mouseOverEvent}
                mouseOutEvent={mouseOutEvent}
            />
            <Footer
                mouseOverEvent={mouseOverEvent}
                mouseOutEvent={mouseOutEvent}
            />
            {/*  BLACK FILTER  */}
            <div className={`mv-black-filter ${burgerOpen ? "filter-active" : ''}`}/>
        </div>
    )
}

export default Main
