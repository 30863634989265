import { useState } from "react";
import { AiFillTwitterCircle, AiFillInstagram, AiFillGithub, AiFillLinkedin, AiFillYoutube } from 'react-icons/ai';
import './footer.css';

const Footer = ({ mouseOutEvent, mouseOverEvent }) => {

    const [isCopy, setIsCopy] = useState(false);

    const copyMail = (e) => {
        navigator.clipboard.writeText(e.target.innerText)
        setIsCopy(true)
        setTimeout(() => {
            console.log('thre')
            setIsCopy(false)
        }, 700);
        return () => clearTimeout()
    }

    return (
        <div className="footer-container">
            <div className="footer-content">
                <div className="footer-content-column">
                    <h3>CONTACT</h3>
                    <p onMouseOver={mouseOverEvent} onMouseOut={mouseOutEvent} onClick={(e) => copyMail(e)}>matthias.vimbert@gmail.com</p>
                    <span className={`clipboard ${isCopy ? 'clipboard-active' : ''}`}>Copied to clipboard !</span>
                    <p>0769119942</p>
                </div>
                <hr/>
                <div className="footer-content-column">
                    <h3>STAY CONNECTED</h3>
                    <div className="footer-content-social">
                        <svg width="0" height="0">
                            <linearGradient id="blue-gradient">
                                <stop stopColor="rgba(41,152,255,1)" offset="0%" />
                                <stop stopColor="rgba(0,255,136,1)" offset="100%" />
                            </linearGradient>
                        </svg>
                        <a href="https://twitter.com/TeksPL" target="_blank" rel="noreferrer"><AiFillTwitterCircle onMouseOver={mouseOverEvent} onMouseOut={mouseOutEvent} style={{ fill: "url(#blue-gradient)" }} /></a>
                        <a href="https://www.instagram.com/matt.lz/" target="_blank" rel="noreferrer"><AiFillInstagram onMouseOver={mouseOverEvent} onMouseOut={mouseOutEvent} style={{ fill: "url(#blue-gradient)" }} /></a>
                        <a href="https://github.com/MatthiasTeks" target="_blank" rel="noreferrer"><AiFillGithub onMouseOver={mouseOverEvent} onMouseOut={mouseOutEvent} style={{ fill: "url(#blue-gradient)" }} /></a>
                        <a href="https://www.linkedin.com/in/matthias-vimbert/" target="_blank" rel="noreferrer"><AiFillLinkedin onMouseOver={mouseOverEvent} onMouseOut={mouseOutEvent} style={{ fill: "url(#blue-gradient)" }} /></a>
                        <a href="https://www.youtube.com/" target="_blank" rel="noreferrer"><AiFillYoutube onMouseOver={mouseOverEvent} onMouseOut={mouseOutEvent} style={{ fill: "url(#blue-gradient)" }} /></a>
                    </div>
                </div>
            </div>
            <div className="footer-copyright">
                <p>
                    © Copyright 2022 - Matthias Vimbert - Freelance à Bordeaux  - Tous droits réservés
                </p>
            </div>
        </div>
    )
}

export default Footer