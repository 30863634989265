import './btnContactRounded.css';
import {PopupWidget} from "react-calendly";

const BtnContactRounded = () => {
    return (
        <div id="mobile-calendly">
            <PopupWidget
                url="https://calendly.com/matthias-vimbert"
                rootElement={document.getElementById("root")}
                text="📅"
                color="transparent"
            />
        </div>
    )
}

export default BtnContactRounded