import { useEffect, useRef, useState } from "react";
import { Routes, Route, Navigate } from 'react-router-dom';
import Main from '../pages/Main';
import Contact from '../pages/Contact';
import Loader from "../pages/Loader";

const Control = (props) => {

    const customCursor = useRef(null); // My custom mouse ref

    const cursorVisible = useRef(true);
    const cursorEnlarged = useRef(false);

    const [isScroll, setIsScroll] = useState(0); /* store value of user scrolling */

    const endX = useRef(window.innerWidth / 2); /* positioning */
    const endY = useRef(window.innerHeight / 2); /* positioning */

    useEffect(() => {
        document.addEventListener('mousedown', mouseOverEvent);
        document.addEventListener('mouseup', mouseOutEvent);
        document.addEventListener('mousemove', mouseMoveEvent);
        document.addEventListener('mouseenter', mouseEnterEvent);
        document.addEventListener('mouseleave', mouseLeaveEvent);
        window.addEventListener('beforeunload', reloadTop)
        window.addEventListener('scroll', handleScroll);


        return () => {
            document.removeEventListener('mousedown', mouseOverEvent);
            document.removeEventListener('mouseup', mouseOutEvent);
            document.removeEventListener('mousemove', mouseMoveEvent);
            document.removeEventListener('mouseenter', mouseEnterEvent);
            document.removeEventListener('mouseleave', mouseLeaveEvent);
            window.removeEventListener("scroll", handleScroll);
            window.removeEventListener('beforeunload', reloadTop);

        };
    }, []); /* Run many event listeners */

    const toggleCursorVisibility = () => { /* Display or hide cursor if user pointer hover window */
        if (cursorVisible.current) {
            customCursor.current.style.opacity = 1;
        } else {
            customCursor.current.style.opacity = 0;
        }
    };

    const toggleCursorSize = () => { /* Function that pimp my cursor if user hover an important section  */
        if (cursorEnlarged.current) {
            customCursor.current.style.background = "rgba(0, 64, 255, 0.6)";
            customCursor.current.style.border = '1px solid blue';
            customCursor.current.style.transform = 'translate(-50%, -50%) scale(2)';
        } else {
            customCursor.current.style.background = 'var(--gradient-color)';
            customCursor.current.style.border = 'none';
            customCursor.current.style.transform = 'translate(-50%, -50%) scale(1)';
        }
    };

    const mouseOverEvent = () => {
        cursorEnlarged.current = true;
        toggleCursorSize();
    };

    const mouseOutEvent = () => {
        cursorEnlarged.current = false;
        toggleCursorSize();
    };

    const mouseEnterEvent = () => {
        cursorVisible.current = true;
        toggleCursorVisibility();
    };

    const mouseLeaveEvent = () => {
        cursorVisible.current = false;
        toggleCursorVisibility();
    };

    const mouseMoveEvent = e => { /* Function that positioning my cursor according to my real cursor */
        cursorVisible.current = true;
        toggleCursorVisibility();

        endX.current = e.clientX;
        endY.current = e.clientY;

        customCursor.current.style.top = endY.current + 'px';
        customCursor.current.style.left = endX.current + 'px';
    };

    const handleScroll = e => { /* Function that store scroll position when user is scrolling */
        setIsScroll(window.scrollY);
    }

    const reloadTop = () => { /* Function that scroll to top if user reload */
        window.scrollTo(0, 0);
    }


    return (
        <>
            <Loader />
            <Routes>
                <Route
                    path="/"
                    element={
                        <Main
                            mouseOverEvent={mouseOverEvent}
                            mouseOutEvent={mouseOutEvent}
                            mouseX={endX}
                            mouseY={endY}
                            handleScroll={handleScroll}
                            isScroll={isScroll}
                        />
                    }
                />
                <Route
                    path="/contact"
                    element={
                        <Contact
                            mouseOverEvent={mouseOverEvent}
                            mouseOutEvent={mouseOutEvent}
                            mouseX={endX}
                            mouseY={endY}
                            handleScroll={handleScroll}
                            isScroll={isScroll}
                        />
                    }
                />
                <Route path="*" element={<Navigate to="/" replace />} /> {/* if route not match, navigate to home */}
            </Routes>

            <div ref={customCursor} className="custom-cursor-mv"/> {/* custom cursor */}
        </>
    )
}

export default Control